import React, { FC } from 'react';
import styled from 'styled-components';
import { StyledMarkdown } from './StyledMarkdown';
import { adaptForLargeScreen } from '@utils/adaptForLargeScreen';
import {
  INNER_MARGIN_RIGHT_MOBILE,
  INNER_MARGIN_LEFT_MOBILE,
} from '@config/layout';
import { mobileMQ } from '@config/mediaQueries';
import { TextPageProps } from 'src/templates/textPage';

const Container = styled.div(
  adaptForLargeScreen({
    marginTop: 168,
    marginLeft: 666,
    marginRight: 163,
    marginBottom: 206,
    fontSize: 20,
    lineHeight: 2,

    [mobileMQ]: {
      marginLeft: INNER_MARGIN_LEFT_MOBILE,
      marginRight: INNER_MARGIN_RIGHT_MOBILE,
      lineHeight: 1.66,
      fontSize: 15,
      fontFamily: 'F37-Beckett-Thin',
      marginBottom: 120,
    },
  }),
);

export const TextPage: FC<TextPageProps> = props => (
  <Container>
    {' '}
    <StyledMarkdown source={props.entry.content} />
  </Container>
);
