import React, { Fragment } from 'react';

import { Meta } from '@components/Meta';
import { Footer } from '@components/Footer';
import { TextPage } from '@components/TextPage';
import UIStore from '@stores/UIStore';
import { Layout } from '@components/Layout';

export type TextPageProps = {
  entry: CmsTextPageEntry;
} & BasePageProps;

type Props = { pageContext: TextPageProps };

export default ({ pageContext: { homepageFonts, entry } }: Props) => {
  UIStore.setupPage();

  return (
    <Fragment>
      <Meta title={entry.title} />

      <Layout homepageFonts={homepageFonts}>
        <TextPage entry={entry} />
      </Layout>

      <Footer />
    </Fragment>
  );
};
